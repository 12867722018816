import { Language } from '../api/appInfo';
import languagesApi from '../api/languages';
import { LanguageEvents } from '../constants/languages';
import { coreExtension } from '../extensionPoints';
import store from '../store';
import {
  NAMESPACE as LANGUAGES_NAMESPACE,
  SET_FALLBACK_LOCALE,
  SET_LANGUAGES,
  SET_LOAD_ERROR,
  SET_LOADING,
  SET_SELECTED_LOCALE,
} from '../store/modules/languages';
import { I18nManager } from './i18nManager';
import { ILanguagesManagerService } from './types';

/**
 * Service to manage the available interface languages
 */
export class LanguagesManagerService implements ILanguagesManagerService {
  /**
   * @inheritdoc
   */
  public getSelectedLocale(): string {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
    return store.getters[`${LANGUAGES_NAMESPACE}/selectedLocale`];
  }

  /**
   * Sets the current locale
   *
   * @param locale - the new locale
   */
  private _setSelectedLocale(locale: string): void {
    store.commit(`${LANGUAGES_NAMESPACE}/${SET_SELECTED_LOCALE}`, locale);
    I18nManager.setLocale(locale);
  }

  /**
   * @inheritdoc
   */
  public setSelectedLocale(locale: string): void {
    this._setSelectedLocale(locale);
    const languages = this.getLanguages();
    const fallbackLanguage =
      languages.find((language) => language.identifier !== locale) || languages[0];
    if (fallbackLanguage) {
      this._setFallbackLocale(fallbackLanguage.identifier);
    }

    coreExtension.eventBus.emit(LanguageEvents.LOCALE_CHANGE, locale);
  }

  /**
   * @inheritdoc
   */
  public getFallbackLocale(): string {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
    return store.getters[`${LANGUAGES_NAMESPACE}/fallbackLocale`];
  }

  /**
   * Sets the fallback locale
   *
   * @param locale - the new locale
   */
  private _setFallbackLocale(locale: string): void {
    store.commit(`${LANGUAGES_NAMESPACE}/${SET_FALLBACK_LOCALE}`, locale);
    I18nManager.setFallbackLocale(locale);
  }

  /**
   * @inheritdoc
   */
  public getLanguages(): Language[] {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
    return store.getters[`${LANGUAGES_NAMESPACE}/languages`];
  }

  /**
   * @param languages - the language objects
   */
  private _setLanguages(languages: Language[]): void {
    store.commit(`${LANGUAGES_NAMESPACE}/${SET_LANGUAGES}`, languages);
  }

  /**
   * @inheritdoc
   */
  public getLoadingLanguages(): boolean {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
    return store.getters[`${LANGUAGES_NAMESPACE}/loadingLanguages`];
  }

  /**
   * @inheritdoc
   */
  public setLoadingLanguages(loading: boolean): void {
    store.commit(`${LANGUAGES_NAMESPACE}/${SET_LOADING}`, loading);
  }

  /**
   * @inheritdoc
   */
  public getLoadError(): boolean {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
    return store.getters[`${LANGUAGES_NAMESPACE}/loadLanguagesError`];
  }

  /**
   * @param hasLoadError - if an error occured while loading
   * the languages or the messages
   */
  private _setLoadError(hasLoadError: boolean): void {
    store.commit(`${LANGUAGES_NAMESPACE}/${SET_LOAD_ERROR}`, hasLoadError);
  }

  /**
   * @inheritdoc
   */
  public async initializeLanguages(languages: Language[]): Promise<void> {
    // Get the current locale
    const oldSelectedLocale = this.getSelectedLocale();

    // Clear loaded languages
    this._setLanguages([]);

    this.setLoadingLanguages(true);
    this._setLoadError(false);

    try {
      let didSetFallback = false;
      let didSetSelected = false;

      this._setLanguages(languages);

      const selectedLocaleIsInConfiguredLanguages = !!languages.find(
        (language) => language.identifier === oldSelectedLocale
      );

      // If the old selected (or initial browser language) is in the app languages, re use it
      // to clean state
      // It doesn't matter if the language messages do not load, so we can do this right away
      if (selectedLocaleIsInConfiguredLanguages) {
        this._setSelectedLocale(oldSelectedLocale);
        didSetSelected = true;
      }

      const loadAllLanguagesPromises = languages.map((language) =>
        this._loadLocaleMessages(language.identifier)
          // eslint-disable-next-line promise/prefer-await-to-then
          .then(() => {
            return { didLoad: true, language };
          })
          .catch(() => {
            this._setLoadError(true);
            return { didLoad: false, language };
          })
      );

      const loadMessagesResults = await Promise.all(loadAllLanguagesPromises);
      for (const result of loadMessagesResults) {
        // Only use locales whose messages did load
        if (!result.didLoad) {
          continue;
        }

        // Use the current iteration locale as the selected locale
        // if we did not already set it
        if (!didSetSelected) {
          this._setSelectedLocale(result.language.identifier);
          didSetSelected = true;
        }

        // Use the next locale (that we did not use as the main locale),
        // to be the fallback locale
        // eslint-disable-next-line promise/always-return
        if (
          !didSetFallback &&
          didSetSelected &&
          this.getSelectedLocale() !== result.language.identifier
        ) {
          this._setFallbackLocale(result.language.identifier);
          didSetFallback = true;
        }
      }

      // If somehow, no locale was set as selected/fallback, just
      // use the first for both
      if (languages.length) {
        if (!didSetSelected) {
          this._setSelectedLocale(languages[0].identifier);
        }
        if (!didSetFallback) {
          this._setFallbackLocale(languages[0].identifier);
        }
      }
      this.setLoadingLanguages(false);
    } catch (err) {
      this.setLoadingLanguages(false);
      this._setLoadError(true);
      throw err;
    }
  }

  /**
   * @inheritdoc
   */
  updatePageLocale() {
    const locale = I18nManager.getI18nLocale(this.getSelectedLocale());
    const htmlEl = document.documentElement;
    htmlEl.setAttribute('lang', locale);
  }

  /**
   * Loads a locale's messages and adds them to the i18n instance
   *
   * @param  locale - the locale for which to load the messages
   * @throws
   */
  private async _loadLocaleMessages(locale: string): Promise<void> {
    let messages: object;
    // The env is defined with webpack so if the condition is false, the code should be removed
    if (process.env.VUE_APP_LOCAL_I18N && process.env.VUE_APP_LOCAL_I18N !== 'false') {
      messages = (await import(`../../../../src/Evolution.Piivo/i18n/${locale}.json`)) as object;
    } else {
      const res = await languagesApi.getLocaleMessages(locale);
      messages = res.body;
    }

    I18nManager.mergeLocaleMessages(locale, messages);
  }
}
